<template>
  <el-button  icon="el-icon-user" type="primary" size="mini" round
    >选择人员</el-button
  >
</template>

<script>
export default {
  name: "UserPicker",
};
</script>
